import { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Modal,
    ModalBody as Body,
    ModalFooter as Footer,
    ModalHeader as Header,
    ModalTitle as Title,
    Spinner,
} from 'react-bootstrap'
import GroupedAction from 'Entity/Collection/ListingSetting/GroupedAction'
import LoadingButton from '../../Button/LoadingButton'
import Api from 'Vendor/Api'
import Collection from 'Config/Collection'
import TextType from 'Vendor/Components/Form/Type/TextType'
import ChoiceType, { Choice } from 'Vendor/Components/Form/Type/ChoiceType'
import { UserContext } from 'Vendor/Context/UserContextProvider'
import Property from 'Entity/Property'

type ReportDefinition = {
    id: number
    name: {
        [key: string]: {
            name: string
            slug: string
        }
    }
}

export interface _ReportingModalProps {
    availableActions: GroupedAction[]
    collection?: Collection
    id?: number
    showActionModals: { [key: string]: boolean | Property }
    handleShowActionModal: (
        groupedAction?: GroupedAction,
        actionFromMain?: boolean,
    ) => void
    fromMain: boolean
    handleShowMainActionModal: () => void
    selected: number[]
    actionModalFormIsLoading: boolean
    actionModalFormIsLoaded: boolean
    setActionModalFormIsLoaded: (isLoaded: boolean) => void
    handleActionModalOnResponse: () => void
    handleActionModalOnSuccess: () => void
    handleActionModalOnSubmit: () => void
}

const _ReportingModal: FC<_ReportingModalProps> = ({
    id,
    availableActions,
    collection,
    showActionModals,
    handleShowActionModal,
    fromMain,
    handleShowMainActionModal,
    selected,
    actionModalFormIsLoading,
    handleActionModalOnResponse,
    handleActionModalOnSuccess,
    handleActionModalOnSubmit,
}) => {
    const { t } = useTranslation()
    const [title, setTitle] = useState<string>('')
    const [intermediateChoice, setIntermediateChoice] = useState<string>('')
    const [availableReports, setAvailableReports] = useState<Choice[]>([])
    const [selectedReport, setSelectedReport] = useState<string>('')
    const [loadingReports, setLoadingReports] = useState<boolean>(true)
    const { currentUser } = useContext(UserContext)
    const userLanguage = currentUser?.currentOrganization?.defaultLanguage as string

    const intermediateChoices: Choice[] = availableActions
        .filter((item) => item.property && item.property.type === 'choice')
        .map((item) => ({
            i18nKey: item?.property?.translations?.[userLanguage]?.label ?? '',
            value: (item?.property?.id ?? '').toString(),
        }))

    useEffect(() => {
        if (showActionModals.reporting && collection) {
            setLoadingReports(true)
            Api.get(`/grouped-action/reporting/definitions/${collection}`)
                .then((response) => {
                    const reports = (Object.values(response?.data?.reportDefinitions || {}) as ReportDefinition[]).map(
                        (report) => ({
                            i18nKey: report.name[userLanguage]?.name ?? '',
                            value: report.id.toString(),
                        })
                    )
                    setAvailableReports(reports)
                })
                .catch(() => {
                    setAvailableReports([])
                })
                .finally(() => {
                    setLoadingReports(false)
                })
        }
    }, [collection, showActionModals.reporting, userLanguage])

    const onSubmit = () => {
        if (title.length > 1 && selectedReport) {
            handleActionModalOnSubmit()
            Api.post(
                `/grouped-action/reporting/${collection}${id ? '/' + id : ''}`,
                {
                    data: {
                        listing: collection,
                        selected,
                        title,
                        intermediateChoice: parseInt(intermediateChoice),
                        reportDefinitionId: parseInt(selectedReport),
                    },
                },
                { responseType: 'blob' }
            )
                .then(() => {
                    handleActionModalOnSuccess()
                })
                .catch(() => { })
                .finally(() => {
                    handleActionModalOnResponse()
                })
        }
    }

    return (
        <Modal show={showActionModals.reporting !== false} onHide={handleShowActionModal}>
            <Header closeButton={!fromMain}>
                <div className="modal-block-header">
                    {fromMain && (
                        <i
                            className="fa-solid fa-angle-left"
                            onClick={handleShowMainActionModal}
                        ></i>
                    )}
                    <Title>
                        {t(`app.groupedActionTitle.reporting.${collection?.replace(/-([a-z])/g, (_, char) => char.toUpperCase())}`, {
                            count: selected.length,
                        })}
                    </Title>
                </div>
            </Header>
            <form>
                <Body>
                    {loadingReports ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <Spinner animation="border" />
                        </div>
                    ) : (
                        <>
                            <ChoiceType
                                i18nLabel="app.groupedActionContent.reporting.reportChoiceLabel"
                                choices={[{ i18nKey: '', value: '' }, ...availableReports]}
                                required={true}
                                value={selectedReport}
                                setValue={(data) => setSelectedReport(Array.isArray(data) ? data[0] : data)}
                            />
                            <TextType
                                id="title"
                                name="title"
                                fullName="title"
                                value={title}
                                setValue={setTitle}
                                required
                                i18nLabel="app.groupedActionContent.reporting.inputLabel"
                            />
                            <ChoiceType
                                i18nLabel="app.groupedActionContent.reporting.choiceLabel"
                                choices={[{ i18nKey: '', value: '' }, ...intermediateChoices]}
                                required={true}
                                value={intermediateChoice}
                                setValue={(data) => setIntermediateChoice(Array.isArray(data) ? data[0] : data)}
                            />
                        </>
                    )}
                    <p className='text-info'>
                        {t(`app.groupedActionContent.reporting.information`, {
                            email: currentUser?.email,
                        })}
                    </p>
                </Body>
                <Footer>
                    <Button variant="secondary" onClick={() => {
                        handleShowActionModal()
                    }}>
                        {t('app.cancel')}
                    </Button>
                    <LoadingButton
                        variant="primary"
                        isLoading={actionModalFormIsLoading}
                        onClick={onSubmit}
                        disabled={loadingReports || availableReports.length === 0}
                    >
                        {loadingReports ? <Spinner as="span" animation="border" size="sm" /> : t('app.save')}
                    </LoadingButton>
                </Footer>
            </form>
        </Modal>
    )
}

export default _ReportingModal
