import { Col } from "react-bootstrap";

interface ChartContainerProps {
    title?: string;
    children: React.ReactNode;
}

const ChartContainer = ({ title, children }: ChartContainerProps) => (
    <Col className="w-100 overflow-hidden d-flex flex-column justify-content-between align-items-center text-center chart-container">
        {title && (
            <div className="chart-title mt-3">
                <p>{title}</p>
            </div>
        )}
        {children}
    </Col>
);

export default ChartContainer;
