interface ChartTitleProps {
    name: string;
}

const ChartTitle = ({ name }: ChartTitleProps) => {
    return (
        <div className="btn btn-primary w-100 text-center py-3 fs-5 fw-normal text-uppercase rounded-pill" style={{ backgroundColor: '#001f3f', border: 'none' }}>
            {name}
        </div>
    );
};

export default ChartTitle;
