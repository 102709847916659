import React, {FC, useContext, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {
    Button,
    Modal,
    ModalFooter as Footer,
    ModalHeader as Header,
    ModalTitle as Title,
    ModalBody as Body,
    Offcanvas
} from "react-bootstrap";
import Timestamp from "../../Timestamp";
import FormEmpty from "Vendor/Components/FormEmpty";
import LoadingButton from "../../Button/LoadingButton";
import useTranslationDataUtils from "Vendor/Utils/TranslationDataUtils";
import File from "Entity/File";
import {UserContext} from "Vendor/Context/UserContextProvider";
import {BannerContext} from "Vendor/Context/BannerContextProvider";
import Bucket from "Entity/Bucket";
import useHumanizeFileSizeUtils from "Vendor/Utils/HumanizeFileSizeUtils";
import ItemStatus from "Config/Collection/ItemStatus";
import _ImportModalTags from "../../Modal/_ImportModalTags";
import Tag from "../../../Entity/Tag";
import Api from "../../../Vendor/Api";
import {classUtils as c} from "Vendor/Utils/ClassUtils";
import {useKeyboardShortcut} from 'Hooks/useKeyboardShortcut';
import {useListingContext} from 'Hooks/useListingContext';
import _ModalComments from '../../Modal/_ModalMentionComments';
import MentionComment from 'Entity/Mention';
import ImagePreview from 'Components/Listing/Item/ImagePreview';

interface FileOffCanvasProps {
    file: File
    bucket: Bucket
    show: boolean
    handleClose: () => void
    onExited: () => void
    onSuccess?: (data: any) => void
    deleteAction: (localFile: File) => void
    editAction: (localFile: File) => void
    editNameAction: (localFile: File) => void
    changeStateOnEdit: (status: boolean) => void
}

const FileOffCanvas: FC<FileOffCanvasProps> = ({file, bucket, show, handleClose, onExited, onSuccess, deleteAction, editAction, editNameAction, changeStateOnEdit}) => {
    const { t } = useTranslation()
    const {currentUser} = useContext(UserContext)
    const { setBanner } = useContext(BannerContext)
    const {translate} = useTranslationDataUtils()
    const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
    const [isFormSuccess, setIsFormSuccess] = useState<boolean>(false)
    const [publishAction, setPublishAction] = useState<number|undefined>(undefined)
    const [unPublishAction, setUnPublishAction] = useState<number|undefined>(undefined)
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [path, setPath] = useState<string>('')
    const [localFile, setLocalFile] = useState<File>({...file})
    const [localTags, setLocalTags] = useState<Tag[]>([])
    const {humanFileSize} = useHumanizeFileSizeUtils()
    const [showOldVersions, setShowOldVersions] = useState<boolean>(false)
    const [canEdit, setCanEdit] = useState<boolean>(false)
    const [canDelete, setCanDelete] = useState<boolean>(false)
    const [hasPlatform, setHasPlatform] = useState<boolean>(currentUser?.currentOrganization?.platforms
        ? currentUser?.currentOrganization?.platforms?.length > 0
        : false)
    const [localComments, setLocalComments] = useState<MentionComment[]>([])
    const [canRestore, setCanRestore] = useState<boolean>(false)

    const { items, currentPage, setCurrentPage, pages } = useListingContext()
    const [currentIndex, setCurrentIndex] = useState(0)
    const itemsArray = Object.values(items)

    const prevItem = currentIndex > 0 ? itemsArray[currentIndex - 1] : null
    const nextItem = currentIndex < itemsArray.length - 1 ? itemsArray[currentIndex + 1] : null

    const isLeftEnabled = isLoaded && (prevItem || pages.includes(currentPage - 1))
    const isRightEnabled = isLoaded && (nextItem || pages.includes(currentPage + 1))

    const mentionSlug = file && file?.mentionPropertyEnabled?.slug

    const handleRightClick = () => {
        if (nextItem) {
            setLocalFile({ ...nextItem })
            setPath(`/file/${nextItem.id}`)
            editAction(nextItem)
            setIsLoaded(false)
        } else {
            goToNextPage()
        }
    }

    const handleLeftClick = () => {
        if (prevItem) {
            setLocalFile({ ...prevItem })
            setPath(`/file/${prevItem.id}`)
            editAction(prevItem)
            setIsLoaded(false)
        } else {
            goToPreviousPage()
        }
    }

    const goToNextPage = () => {
        if (pageExists(currentPage + 1)) {
            onExited()
            setCurrentPage(currentPage + 1)
        }
    }

    const goToPreviousPage = () => {
        if (pageExists(currentPage - 1)) {
            onExited()
            setCurrentPage(currentPage - 1)
        }
    }

    const pageExists = (page: number) => {
        return pages.includes(page)
    }

    useKeyboardShortcut({
        key: "ArrowLeft",
        onKeyPressed: () => handleLeftClick(),
        isEnabled: isLeftEnabled,
    })

    useKeyboardShortcut({
        key: "ArrowRight",
        onKeyPressed: () => handleRightClick(),
        isEnabled: isRightEnabled,
    })

    useEffect(() => {
        const isDeleted = file.deletedAt !== null && file.deletedAt !== undefined
        const securities = bucket.listingSetting.securities

        const setPermissions = (canModify: boolean, canDelete: boolean) => {
            setCanEdit(canModify)
            setCanDelete(canDelete)
        };

        if (currentUser && securities !== false && ((currentUser.administrator && bucket.listingSetting.securities === true)
            || (file.group === null || file.group === undefined))) {

            if (isDeleted) {
                setCanRestore(true)

                return
            }
            setPermissions(true, true)

            return
        }

        if (typeof securities === 'object' && securities !== null && file.group !== null
            && file.group !== undefined
            && typeof file.group.id === 'number'
            && file.group.id > 0) {
            const groupSecurities = securities[file.group.id]
            if (groupSecurities) {
                setPermissions(groupSecurities.canModify, groupSecurities.canDelete)

                return
            }
        }

        setPermissions(false, false)
    }, [currentUser, file, bucket])


    useEffect(() => {
        setLocalFile({ ...file })
        setLocalTags([...file.tags])
        if (Array.isArray(file[mentionSlug])) {
            setLocalComments([...file[mentionSlug]]);
        } else {
            //Fix for null value of mention
            setLocalComments([])
        }
    }, [file])

    useEffect(() => {
        setPath(`/file/${localFile.id}`)
    }, [localFile])

    useEffect(() => {
        const index = itemsArray.findIndex((item) => item.id === localFile.id);
        setCurrentIndex(index);
    }, [itemsArray, localFile]);

    const getStatusColor = (status: ItemStatus) => {
        if (hasPlatform) {
            if (status === 'published') return 'bg-success'
            if (status === 'unpublished') return 'bg-danger'
        }

        if (status === 'archived') return 'bg-muted'

        return 'd-none'
    }

    const regeneratePublicPath = () => {
        Api.patch(`/file/${localFile.id}/publicId/generate`)
            .then((result) => {
                const newLocalFile = {...localFile};
                newLocalFile.public = result.data.publicPath;
                setLocalFile(newLocalFile);
            });
    }

    const deletePublicPath = () => {
        Api.patch(`/file/${localFile.id}/publicId/delete`)
            .then((result) => {
                const newLocalFile = {...localFile};
                newLocalFile.public = null;
                setLocalFile(newLocalFile);
            });
    }

    const handleOldVersions = () => {
        setShowOldVersions(false)
    }
    let badge = ''

    if (file.mimeType === 'application/postscript') {
        //use extension
        let extension = file.name.split('.').pop()
        badge = extension?.toUpperCase() ?? 'PS'
    } else {
        badge = t(`app.mimes.${file.mimeType}`)
    }

    const appendOnTimestamp = () => {
        const size = localFile?.__size
            ? ', ' + humanFileSize(localFile.__size)
            : ''

        const format = localFile?.format?.translations && Object.keys(localFile?.format?.translations).length > 0
            ? ', ' +
              localFile?.format.translations[
                  currentUser?.currentOrganization?.defaultLanguage as string
              ].label
            : ''

        return size + format
    }

    return <>
        <Offcanvas show={show} onHide={handleClose} placement="end" onExited={onExited} className="offcanvas-width-xl box-file-edit offcanvas-collection">
            <Offcanvas.Header closeButton>
                <h2 className="h3">
                    {localFile.name}
                    {canEdit &&
                        <Button variant="outline-primary" onClick={() => {
                            editNameAction(localFile)
                        }}>
                            <i className="fa-solid fa-pencil"></i>
                        </Button>
                    }
                </h2>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="controls">
                    <Button onClick={handleLeftClick} className="left-button" disabled={!isLeftEnabled}>
                        <i className="fa-solid fa-chevron-left"></i>
                    </Button>
                    <Button onClick={handleRightClick} className="right-button" disabled={!isRightEnabled}>
                        <i className="fa-solid fa-chevron-right"></i>
                    </Button>
                </div>
                <Timestamp item={localFile} blamable append={appendOnTimestamp()} />
                <div className="row justify-content-between align-items-center w-100">
                    <div className="col">
                        <h2 className="h3">
                            <p>{badge && <span className="badge bg-gray-light ms-2">{badge}</span>}</p>
                            <span className={c('badge ms-2', getStatusColor(localFile.status))}>{t(`app.item_status.${localFile.status}`)}</span>
                            {/* <Button variant="outline-primary" onClick={() => {
                                editAction(localFile)
                            }}><i className="fa-solid fa-pencil"></i></Button> */}
                        </h2>
                        {/* <Timestamp item={localFile} blamable append={localFile.size ? ', ' + humanFileSize(localFile.size) : ''} /> */}
                    </div>
                    {localFile.currentVersion > 1 && <div className="col d-flex justify-content-end">
                        <Button className="m-0" variant="secondary" onClick={e => {
                            setShowOldVersions(true)
                        }}>V{localFile.currentVersion}</Button>
                    </div>}
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <h3>{t('app.file.publicLink')}</h3>
                        <div className="input-group ml-2">
                            <input type="text" className="form-control" readOnly={true} placeholder={t('app.file.publicLink')} value={localFile.public ?? ""} />
                            {localFile.public && <Button variant="light" onClick={() => {navigator.clipboard.writeText(localFile.public)}}>
                                {t('app.file.copyPublicLink')}
                            </Button>}
                            <Button variant="light" onClick={regeneratePublicPath}>
                                {t(localFile.public ? 'app.file.regeneratePublicLink' : 'app.file.generatePublicLink')}
                            </Button>
                            {localFile.public && <Button variant="light" onClick={deletePublicPath}>
                                {t('app.file.deletePublicLink')}
                            </Button>}
                        </div>
                    </div>
                </div>
                {file && file.thumbnail && (
                    <ImagePreview src={localFile.thumbnail?.replaceAll("__SIZING__", "extra-large") || ""} />
                )}
                <FormEmpty
                    path={path}
                    formPath={!!localFile.id}
                    method={localFile.id ? 'patch' : 'post'}
                    onSubmit={() => {
                        setIsFormLoading(true)
                    }}
                    setIsLoaded={setIsLoaded}
                    onSuccess={(data) => {
                        changeStateOnEdit(true)
                        setIsFormSuccess(true)
                        setBanner({type: 'success', i18nTitle: 'app.save', i18nMessage: 'app.data_saved_message'})
                        setLocalFile(data as File)
                        onSuccess && onSuccess(data)
                    }}
                    onResponse={() => {
                        setIsFormLoading(false)
                    }}
                    appendForm={<div className="mb-3">
                        {file && file.enableTags && <_ImportModalTags tags={localTags} setTags={setLocalTags} onLoad={!isLoaded || isFormLoading} headingLevel={3} disabled={!canEdit} />}
                        {file && file.mentionPropertyEnabled?.enabled &&  <_ModalComments comments={localComments} setComments={setLocalComments} entity={file} entityType="file" disabled={!canEdit} /> }
                    </div>}
                    additionalData={canRestore ? {
                        restore: true,
                    } : hasPlatform
                        ? {
                            tags: localTags,
                            publish: publishAction,
                            unPublish: unPublishAction,
                            ...(mentionSlug ? { [mentionSlug]: localComments.map(comment => comment.comment) } : []),
                        }
                        : {
                            tags: localTags,
                            ...(mentionSlug ? { [mentionSlug]: localComments.map(comment => comment.comment) } : []),
                        }
                    }
                    className="mb-3"
                    formSubmit={{ className: 'footer' }}
                    disabled={!canRestore && !canEdit}
                >
                    <div className="row justify-content-center align-items-center mt-3">
                        {canEdit && <div className="col-auto">
                            {['draft', 'unpublished'].includes(file.status) && hasPlatform && <LoadingButton
                                size="lg"
                                variant="success"
                                className="me-3"
                                type="submit"
                                onClick={() => {
                                    setPublishAction(1)
                                    setUnPublishAction(undefined)
                                    setIsFormSuccess(false)
                                }}
                                success={publishAction === 1 && isFormSuccess}
                                setSuccess={setIsFormSuccess}
                                isLoading={isFormLoading && publishAction === 1}
                                disabled={!isLoaded || isFormLoading}>
                                {t('app.publish')}
                            </LoadingButton>}
                            {'published' === file.status && hasPlatform && <LoadingButton
                                size="lg"
                                variant="danger"
                                className="me-3"
                                type="submit"
                                onClick={() => {
                                    setPublishAction(undefined)
                                    setUnPublishAction(1)
                                    setIsFormSuccess(false)
                                }}
                                success={unPublishAction === 1 && isFormSuccess}
                                setSuccess={setIsFormSuccess}
                                isLoading={isFormLoading && unPublishAction === 1}
                                disabled={!isLoaded || isFormLoading}>
                                {t('app.unPublish')}
                            </LoadingButton>}
                            <LoadingButton
                                size="lg"
                                type="submit"
                                variant="tertiary"
                                isLoading={isFormLoading && publishAction !== 1 && unPublishAction !== 1}
                                disabled={!isLoaded || isFormLoading}
                                success={publishAction !== 1 && unPublishAction !== 1 && isFormSuccess}
                                setSuccess={setIsFormSuccess}
                                onClick={() => {
                                    setPublishAction(undefined)
                                    setUnPublishAction(undefined)
                                    setIsFormSuccess(false)
                                }}
                            >
                                {t('app.save')}
                            </LoadingButton>
                        </div>}
                        {!canEdit && canRestore && <div className="col-auto">
                            <LoadingButton
                                size="lg"
                                type="submit"
                                variant="warning"
                                isLoading={isFormLoading}
                                disabled={!isLoaded || isFormLoading}
                                success={isFormSuccess}
                                setSuccess={setIsFormSuccess}
                                onClick={() => {
                                    setIsFormSuccess(false)
                                }}
                            >
                                {t('app.restore')}
                            </LoadingButton>
                        </div>}
                    </div>
                </FormEmpty>
            </Offcanvas.Body>
        </Offcanvas>
        <Modal show={showOldVersions} onHide={handleOldVersions} centered>
            <Header closeButton>
                <Title>{t('app.old_versions')}</Title>
            </Header>
            <Body>
                <div className="list-group">
                    {file?.versions?.map((version, index) =>
                        <Button href={`${file.view}?download=1&version=${version.versionNumber}`} target="_blank" variant="outline-primary" key={index} className="list-group-item list-group-item-action">
                            <h5>V{version.versionNumber}</h5>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <span className="icon">
                                    <img src={version.thumbnail?.replaceAll('__SIZING__', version.versionNumber + '_' + 'medium')} alt={file.name ?? file.id} />
                                </span>
                                <span className="size">{version.size ? humanFileSize(version.size) : t('app.error')}</span>
                            </div>
                            <Timestamp item={version} blamable />
                        </Button>
                    )}
                </div>
            </Body>
            <Footer>

            </Footer>
        </Modal>
    </>
}

export default FileOffCanvas
