import { RouteObject } from "react-router-dom";
import AppPage from "Pages/Admin/Dashboard/AppPage";
import PlatformPage from "Pages/Admin/Dashboard/PlatformPage";

const DashboardRouting: RouteObject[] = [
    {
        path: '',
        element: <AppPage />,
    },
    {
        path: 'platform',
        element: <PlatformPage />,
    },
]

export default DashboardRouting
