import React, {FC} from 'react'
import {
    Offcanvas,
    OffcanvasBody as Body,
} from 'react-bootstrap'
import Shopping from "../../../Entity/Shopping";
import {useTranslation} from "react-i18next";
import {classUtils as c} from "Vendor/Utils/ClassUtils";
import _Show from "./_Show";
import _Message from "./_Message";
import EntityInterface from 'Vendor/Definition/EntityInterface';
import { ThumbnailModalProps } from 'Components/Listing/View/_CardGroup';


export type ShoppingOffCanvasProps = {
    show: boolean
    handleClose: () => void
    shopping?: Shopping,
    setShopping: (shopping: Shopping) => void
    currentMenu: ShoppingCurrentMenu
    setCurrentMenu: (currentMenu: ShoppingCurrentMenu) => void
    setInfoShow: (show: boolean) => void
    setThumbnailModalProps?: (thumbnailPath: ThumbnailModalProps) => void
    editProductAction?: (item: EntityInterface) => void
}

export type ShoppingCurrentMenu = 'show' | 'message'

const ShoppingOffCanvas: FC<ShoppingOffCanvasProps> = (
    {
        show,
        handleClose,
        shopping,
        setShopping,
        currentMenu,
        setCurrentMenu,
        setInfoShow,
        setThumbnailModalProps,
    }) => {

    const {t} = useTranslation()

    return (
        <>
            <Offcanvas show={show} onHide={handleClose} placement="end" className="offcanvas-large" backdropClassName="offcanvas-backdrop-large">
                <Body className="placeholder-glow">
                    <div className="header">
                        <div className="sidebar">
                            <span>{t(`app.${shopping?.type ?? 'shopping'}`) + ' #' + shopping?.id}</span>
                            <span className={c("badge-status", shopping ? `status-${shopping.status}` : 'status-draft placeholder')}>{shopping && t(`app.shopping_status.${shopping.status}`)}</span>
                        </div>
                        <div className="content">
                            <ul className={c('menu', !shopping && 'disabled')}>
                                <li className={c(currentMenu === 'show' && 'active')}>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault()
                                        setCurrentMenu('show')
                                    }}>{t('app.products')}</a>
                                </li>
                                {/*<li className={c(currentMenu === 'message' && 'active', "disabled")}>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault()
                                        //setCurrentMenu('message')
                                    }}>{t('app.messages')}</a>
                                </li>*/}
                            </ul>
                        </div>
                    </div>
                    <div className="body">
                        {currentMenu === 'show' && <_Show
                            shopping={shopping}
                            setShopping={setShopping}
                            setInfoShow={setInfoShow}
                            setThumbnailModalProps={setThumbnailModalProps}
                        />}
                        {/*currentMenu === 'message' && <_Message shopping={shopping} />*/}
                    </div>
                </Body>
            </Offcanvas>
        </>
    )
}

export default ShoppingOffCanvas
