import { BarChart as ReBarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import { LayoutType } from 'recharts/types/util/types';

interface BarChartProps {
    layout: LayoutType;
    data: any[];
    xAxisKey: string;
    yAxisType: 'category' | 'number';
    dataKeys: string[];
    colors: string[];
    stacked?: boolean;
    displayLegend?: boolean;
}

const renderCustomLegend = (props: any, colors: string[]) => {
    const { payload } = props;

    return (
        <ul style={{ display: 'flex', justifyContent: 'center', listStyle: 'none', padding: 0 }}>
            {payload.map((entry: any, index: any) => (
                <li key={`item-${index}`} style={{ marginRight: 10, display: 'flex', gap: '4px', justifyContent: 'center' }}>
                    <div style={{ width: '16px', height: '16px', backgroundColor: colors[index % colors.length] }}></div>
                    <span style={{ color: '#000', fontSize: '14px' }}>{entry.value}</span>
                </li>
            ))}
        </ul>
    );
}

const BarChart = ({ layout, data, xAxisKey, yAxisType, colors, stacked = false, dataKeys, displayLegend = true }: BarChartProps) => {

    const defaultData = [
        { [xAxisKey]: '0', property1: 0, property2: 0, property3: 0 },
        { [xAxisKey]: '1', property1: 0, property2: 0, property3: 0 },
        { [xAxisKey]: '2', property1: 0, property2: 0, property3: 0 }
    ];

    const chartData = (data && data.length > 0) ? data : defaultData;

    const keys = dataKeys;

    return (
        <ResponsiveContainer width="100%" height={350}>
            <ReBarChart layout={layout} data={chartData} barSize={100} margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
            }}>
                <CartesianGrid strokeDasharray="3 3" />
                {layout === 'horizontal' ? (
                    <>
                        <XAxis dataKey={xAxisKey} />
                        <YAxis type={yAxisType} />
                    </>
                ) : (
                    <>
                        <XAxis type={yAxisType} />
                        <YAxis dataKey={xAxisKey} type="category" />
                    </>
                )}
                {/* <Tooltip itemStyle={{ color: 'black' }} /> */}
                <Tooltip />
                {displayLegend && <Legend align="center" verticalAlign="top" content={(props) => renderCustomLegend(props, colors)} />}
                {keys && keys?.map((key, index) => (
                    <Bar
                        key={key}
                        dataKey={key}
                        name={key?.charAt(0).toUpperCase() + key.slice(1)}
                        stackId={stacked ? 'a' : undefined}
                        fill={colors[index % colors.length]}
                    />
                ))}
            </ReBarChart>
        </ResponsiveContainer>
    );
};

export default BarChart;
