import React, {CSSProperties, FC, ReactNode, useContext, useEffect, useState} from 'react'
import EntityInterface from "Vendor/Definition/EntityInterface";
import {useTranslation} from "react-i18next";
import IsGrantedInterface from "Vendor/Definition/IsGrantedInterface";
import TranslationsInterface from "Vendor/Definition/TranslationsInterface";
import {Item, Placeholders, Rows, RowType} from "../../Layout/Table";
import {UserContext} from "Vendor/Context/UserContextProvider";
import TableColumn from "Entity/Collection/ListingSetting/View/TableColumn";
import useTranslationDataUtils from "Vendor/Utils/TranslationDataUtils";
import Spinner from "Components/Spinner";
import {Button} from "react-bootstrap";
import {CustomAction, getFilterChoiceKey, ListingItems, OptionsType, OptionType} from "../Listing";
import Organization from "../../../Entity/Organization";
import Collection from "../../../Config/Collection";
import {ThumbnailModalProps} from "./_CardGroup";
import ListingSetting from "../../../Entity/Collection/ListingSetting";
import {classUtils as c} from "Vendor/Utils/ClassUtils";
import Shopping from "../../../Entity/Shopping";
import {Link} from "react-router-dom";
import {CollectionContext} from "../../../Vendor/Context/CollectionContextProvider";
import LoadingButton from "../../Button/LoadingButton";
import Contact from "../../../Entity/Contact";
import Company from "../../../Entity/Company";

export const generateRows = (tableColumns: TableColumn[], organization: Organization, entity?: Collection, setting?: ListingSetting): Rows => {
    let rows: Rows = {}
    let defaultPlaceholders: Placeholders = [
        {
            element: 'p',
            size: 1
        }
    ]

    tableColumns.forEach(tableColumn => {
        const isShoppingRelated = (entity === 'shopping' || entity === 'pre-order')

        let row: RowType = {
            placeholders: defaultPlaceholders
        }
        let name: string = ''

        if (tableColumn.property) {
            row.translatedLabelValues = tableColumn.property
            row.translatedLabelField = 'label'
            row.property = tableColumn.property
            name = tableColumn.property.slug
        } else if (tableColumn.columnType) {
            switch (tableColumn.columnType) {
                case 'identifier':
                    name = 'id'
                    break;
                case 'company':
                    name = organization?.setting?.relationContactCompany === 'many' ? 'companies' : 'company'
                    break;
                case 'name':
                    if (entity && ['catalog', 'data-collection'].includes(entity)) {
                        row.translated = true
                    } else if (entity && isShoppingRelated) {
                        row.render = (item: EntityInterface) => {
                            let shopping = item as Shopping

                            return (setting?.shoppingType ? ' ' : '') + '#' + shopping.id
                        }
                        if (setting?.shoppingType) {
                            row.i18nPrefix = 'app.' + setting?.shoppingType
                        }
                    }

                    row.editField = true
                    name = 'name'
                    break;
                case 'product':
                    if (entity && isShoppingRelated) {
                        row.render = (item: EntityInterface) => {
                            let shopping = item as Shopping
                            let style: CSSProperties = {
                                backgroundImage: `url(${(shopping.thumbnail?.replaceAll('__SIZING__', 'small'))})`,
                            }

                            return <i style={style}><span>{shopping.productNumber}</span></i>
                        }
                        row.className = 'rounded-thumbnail'
                    }
                    name = 'products'
                    break;
                case 'contact':
                    name = 'contact'
                    if (entity && isShoppingRelated) {
                        row.render = (item: EntityInterface) => {
                            let shopping = item as Shopping

                            let type = shopping.customerType
                            let customer = (shopping.customer) ? shopping.customer.firstName + ' ' + shopping.customer.lastName : null
                            let company = shopping.company?.name

                            if (customer && company) {
                                if (type === 'company') {
                                    return <>
                                        <span>{company}</span>
                                        <i>{customer}</i>
                                    </>
                                }

                                return <>
                                    <span>{customer}</span>
                                    <i>{company}</i>
                                </>
                            }

                            if (company) {
                                return <span>{company}</span>
                            }

                            if (customer) {
                                return <span>{customer}</span>
                            }

                            return <i className="text-muted">-</i>
                        }
                        row.className = 'multi-line'
                        name = 'customer'
                    }
                    break;
                case 'status':
                    name = 'status'
                    row.statusField = true
                    break;
                case 'totalPreOrders':
                    row.render = (i: EntityInterface) => {
                        if (entity) {
                            let item = (entity === 'company') ? i as Company : (entity === 'contact') ? i as Contact : i

                            return <span>{item.totalPreOrders !== 0 && item.totalPreOrders}</span>
                        }
                    }
                    row.className = 'rounded-thumbnail'
                    name = 'totalPreOrders'
                    break;
                case 'totalShoppings':
                    row.render = (i: EntityInterface) => {
                        if (entity) {
                            let item = (entity === 'company') ? i as Company : (entity === 'contact') ? i as Contact : i
                            return <span>{item.totalShoppings !== 0 && item.totalShoppings}</span>
                        }
                    }
                    row.className = 'rounded-thumbnail'
                    name = 'totalShoppings'
                    break;
                case 'currentPreOrders':
                    row.render = (i: EntityInterface) => {
                        if (entity) {
                            let item = (entity === 'company') ? i as Company : (entity === 'contact') ? i as Contact : i
                            return <span>{item.currentPreOrders !== 0 && item.currentPreOrders}</span>
                        }
                    }
                    row.className = 'rounded-thumbnail'
                    name = 'currentPreOrders'
                    break;
                case 'currentShoppings':
                    row.render = (i: EntityInterface) => {
                        if (entity) {
                            let item = (entity === 'company') ? i as Company : (entity === 'contact') ? i as Contact : i

                            return <span>{item.currentShoppings !== 0 && item.currentShoppings}</span>
                        }
                    }
                    row.className = 'rounded-thumbnail'
                    name = 'currentShoppings'
                    break;
                default:
                    name = tableColumn.columnType ?? '--error--'
                    break;
            }
        } else if (tableColumn.column) {
            name = tableColumn.column
            row.field = tableColumn.column
        } else {
            name = '--error--'
        }

        if (tableColumn.hasOwnProperty('render')) {
            row.render = tableColumn.render
        }

        if (tableColumn.hasOwnProperty('field')) {
            row.field = tableColumn.field
        }

        if (tableColumn.hasOwnProperty('translated')) {
            row.translated = tableColumn.translated
        }

        if (tableColumn.hasOwnProperty('i18nPlaceholder')) {
            row.i18nPlaceholder = tableColumn.i18nPlaceholder
        }

        rows[name] = row
    })

    return rows
}

export interface _TableProps {
    isLoaded: boolean
    onError: boolean
    rows: Rows
    items: ListingItems
    editAction?: (item: EntityInterface) => void
    editUri?: string
    deleteAction?: (item: EntityInterface) => void
    options: OptionsType | undefined,
    selectable: boolean
    selected: number[]
    setSelected: (selected: number[]) => void
    selectMultiple: boolean
    entity?: Collection
    setThumbnailModalProps: (thumbnailPath: ThumbnailModalProps) => void
    setting: ListingSetting
    customActions?: CustomAction[]
}

type Action = 'edit' | 'delete'

const _Table: FC<_TableProps> = ({
                                     isLoaded,
                                     onError,
                                     rows,
                                     items,
                                     editAction,
                                     editUri,
                                     deleteAction,
                                     options,
                                     selectable,
                                     selected,
                                     setSelected,
                                     selectMultiple,
                                     entity,
                                     setThumbnailModalProps,
                                     setting,
                                     customActions,
                                 }) => {
    const {t, i18n} = useTranslation()
    const {translate} = useTranslationDataUtils()
    const {currentUser} = useContext(UserContext)
    const {getValue} = useContext(CollectionContext)

    const can = (item: Item, action: Action): boolean => {
        if ('delete' === action && (entity === 'shopping' || entity === 'pre-order')) {
            if (!['draft', 'validated', 'sent', 'refused'].includes(item.status)) return false;
        }

        if (false === item?.isGranted?.[action]) {
            return false;
        }

        if (
            currentUser
            && setting.securities !== false
            && (
                (currentUser.administrator && setting.securities === true)
                || (item.group === null || item.group === undefined || item.group.id === null || item.group.id === undefined || item.group.id === 0)
            )
            && (item.deletedAt === undefined || item.deletedAt === null)
        ) {
            return true;
        } else if (
            typeof setting.securities === 'object'
            && null !== setting.securities
            && item.group !== null
            && item.group !== undefined
            && typeof item.group.id === 'number'
            && item.group.id > 0
            && setting.securities.hasOwnProperty(item.group.id)
        ) {
            return action === 'edit' ? setting.securities[item.group.id].canModify : setting.securities[item.group.id].canDelete;
        }

        return false;
    }

    const render = (row: RowType, name: string, item: Item): ReactNode => {
        let currentLanguage = i18n.language

        let byProperty = (() => {
            if (row.property) {
                return getValue(item, row.property)
            }

            return <></>
        })

        let byTranslated = (() => {
            if (item.hasOwnProperty('translations')) {
                if (!item.translations) {
                    throw new Error('Item translation object not found');
                }
                if (item.translations.hasOwnProperty(currentLanguage)) {
                    if (item.translations[currentLanguage].hasOwnProperty(name)) {
                        return item.translations[currentLanguage][name as keyof Item]
                    }
                }

                if (!currentUser) {
                    return <></>
                }
                if (!currentUser.currentOrganization) {
                    return <></>
                }
                if (!currentUser.currentOrganization.defaultLanguage) {
                    return <></>
                }

                if (item.translations.hasOwnProperty(currentUser.currentOrganization.defaultLanguage)) {
                    if (item.translations[currentUser.currentOrganization.defaultLanguage].hasOwnProperty(name)) {
                        return item.translations[currentUser.currentOrganization.defaultLanguage][name as keyof Item] + ' (' + currentUser.currentOrganization.defaultLanguage + ')'
                    }
                }

                return <></>
            }
        })

        let byValue = (() => {
            let value: object | string | null | number | IsGrantedInterface | TranslationsInterface | undefined = item[name as keyof Item]

            if (typeof value === 'object' && !(value instanceof Array)) {
                if (value === null) {
                    if (row.i18nPlaceholder) {
                        return <i>{t(row.i18nPlaceholder)}</i>
                    }
                } else if (row.field) {
                    if (value.hasOwnProperty(row.field)) {
                        // @ts-ignore
                        if (!value[row.field]) {
                            if (row.i18nPlaceholder) {
                                return <i>{t(row.i18nPlaceholder)}</i>
                            }
                        } else {
                            // @ts-ignore
                            return value[row.field]
                        }
                    }
                } else {
                    return <i className="text-danger">error</i>
                }
            } else if (typeof value === 'object') {
                if (!value || value.length === 0) {
                    if (row.i18nPlaceholder) {
                        return <i>{t(row.i18nPlaceholder)}</i>
                    }
                } else if (row.i18nKey) {
                    let text = '';

                    value.forEach((data, i, array) => {
                        text += t(row.i18nKey + data)
                        if (i < (array.length - 1)) {
                            text += ', '
                        }
                    })

                    return text
                } else {
                    return value && value.length ? value.join(', ') : ''
                }
            } else if (typeof value === 'number') {
                return value
            } else if (!value) {
                if (row.i18nPlaceholder) {
                    return <i>{t(row.i18nPlaceholder)}</i>
                }
            } else if (row.i18nKey) {
                return t(row.i18nKey + value)
            }

            return value
        })

        let nodes: ReactNode[] = [];

        if (row.i18nPrefix) {
            nodes.push(t(row.i18nPrefix));
        }

        if (row.render) {
            nodes.push(row.render(item));
        } else if (row.property) {
            if (options === undefined) {
                nodes.push(<i className="fa-solid fa-circle-notch fa-spin"></i>);
            } else {
                nodes.push(byProperty());
            }
        } else if (['createdAt', 'updatedAt', 'designedDelivery', 'plannedReturn', 'sendAt'].includes(name)) {
            if (item.hasOwnProperty(name) && item[name]) {
                nodes.push((new Date(item[name])).toLocaleDateString(currentLanguage, {
                    weekday: 'short',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                }))
            }
        } else if (['createdBy', 'updatedBy'].includes(name)) {
            if (item.hasOwnProperty(name) && item[name] && item[name].hasOwnProperty('name')) {
                nodes.push(item[name].name);
            } else {
                nodes.push(<i>{t('app.unknown')}</i>);
            }
        } else if (name === 'status') {
            if (entity === 'shopping') {
                nodes.push(item.hasOwnProperty('status') && item.status
                    ? t(`app.shopping_status.${item.status}`)
                    : t('app.empty')
                );
            } else {
                nodes.push(item.hasOwnProperty('status') && item.status
                    ? t(`app.item_status.${item.status}`)
                    : t('app.empty')
                );
            };
        } else if (name === 'company') {
            nodes.push(item.hasOwnProperty('company') && item.company ? item.company.name : <></>);
        } else if (name === 'companies') {
            nodes.push(item.hasOwnProperty('companies') && Array.isArray(item.companies) && item.companies.length
                ? item.companies.slice(0, 5).map((company: Company) => {
                    return company.name
                }).join(', ') + (item.companies.length > 5 ? ', ...' : '')
                : <></>
            );
        } else if (name === 'group') {
            nodes.push(item.hasOwnProperty('group') && item.group ? item.group.name : <></>);
        } else if (name === 'price') {
            nodes.push(item.price);
        } else if ('mailingList' === name) {
          if (item.hasOwnProperty('mailingLists') && Array.isArray(item.mailingLists)) {
              item.mailingLists.forEach((mailingList, index, array) => {
                    nodes.push(<span key={index}>{translate(mailingList, 'name')}{(index + 1) < array.length ? ', ' : ''}</span>);
              })
          }
        } else if (row.translated) {
            nodes.push(byTranslated());
        } else if (item.hasOwnProperty(name)) {
            nodes.push(byValue());
        }

        return <>{nodes.map((node, index) => <React.Fragment key={index}>{node}</React.Fragment>)}</>;
    }

    return (
        <div className="container-fluid bg-light p-3 mb-3 rounded-4">
            <table
                className={c('table table-hover table-large')}> {/* setting.tableSize && `table-${setting.tableSize}` */}
                <thead>
                <tr>
                    {selectable &&
                        <th></th>
                    }
                    {entity === 'bucket' &&
                        <th></th>
                    }
                    {Object.keys(rows).map((key, index) => {
                        let row = rows[key]
                        return (
                            <th key={index}>
                                {row.translatedLabelField && row.translatedLabelValues
                                    ? translate(row.translatedLabelValues, row.translatedLabelField)
                                    : t(row.i18nLabel ?? `app.${key}`)
                                }
                            </th>
                        )
                    })}
                    <th key={Object.keys(rows).length}>{t('app.actions')}</th>
                </tr>
                </thead>
                <tbody>
                {isLoaded && Object.keys(items).length ? (
                    Object.values(items).map((item, key) => {
                        return (
                            <tr key={key} className={c(selected.includes(item.id ?? 0) && 'selected', item.deletedAt ? 'row-archived' : '')} onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                if (selectable && item.id && (!item.hasOwnProperty('deletedAt') || item.deletedAt === null)) {
                                    let tempSelected = [...selected]
                                    if (tempSelected.includes(item.id)) {
                                        let index = tempSelected.indexOf(item.id)
                                        tempSelected.splice(index, 1)
                                    } else {
                                        if (!selectMultiple) {
                                            tempSelected = [item.id]
                                        } else {
                                            tempSelected.push(item.id)
                                        }
                                    }
                                    setSelected(tempSelected)
                                }
                            }}>
                                {selectable &&
                                    <td className="select">
                                            <label>
                                                <input type="checkbox"
                                                       onChange={() => {
                                                            if (selectable && item.id) {
                                                                let tempSelected = [...selected]
                                                                if (tempSelected.includes(item.id)) {
                                                                    let index = tempSelected.indexOf(item.id)
                                                                    tempSelected.splice(index, 1)
                                                                } else {
                                                                    if (!selectMultiple) {
                                                                        tempSelected = [item.id]
                                                                    } else {
                                                                        tempSelected.push(item.id)
                                                                    }
                                                                }
                                                                setSelected(tempSelected)
                                                            }
                                                        }}
                                                       checked={selected.includes(item.id ?? 0)}
                                                       disabled={!item.hasOwnProperty('deletedAt') || item.deletedAt === null}
                                                />
                                                <span></span>
                                            </label>
                                    </td>
                                }
                                {entity === 'bucket' &&
                                    <td className="thumbnail">
                                        {item.hasOwnProperty('thumbnail') && item.thumbnail &&
                                            <a href="#" onClick={() => {
                                                setThumbnailModalProps({
                                                    path: item.mimeType?.includes("image") ? item.thumbnail?.replaceAll('__SIZING__', 'extra-large') : item.view ?? '',
                                                    name: item.name ?? item.id,
                                                    mimeType: item.mimeType ?? '',
                                                })
                                            }}>
                                                {/*<img src={item.thumbnail.replaceAll('__SIZING__', 'small')}  alt={item.name ?? item.id} />*/}
                                                {item.mimeType?.includes("image") ? (
                                                    <img src={item.thumbnail?.replaceAll('__SIZING__', 'small')}
                                                         alt={item.name ?? item.id}/>
                                                ) : (
                                                    item.mimeType?.includes("video") ? (
                                                        <div
                                                            className="d-flex justify-content-center align-items-center"
                                                            style={{
                                                                width: '32px',
                                                                height: '32px',
                                                                backgroundColor: '#fff'
                                                            }}>
                                                            <i className="fas fa-video" style={{color: '#ECE5E0'}}></i>
                                                        </div>
                                                    ) : (
                                                        item.mimeType?.includes("presentation") ? (
                                                            <div
                                                                className="d-flex justify-content-center align-items-center"
                                                                style={{
                                                                    width: '32px',
                                                                    height: '32px',
                                                                    backgroundColor: '#fff'
                                                                }}>
                                                                <i className="fas fa-presentation"
                                                                   style={{color: '#ECE5E0'}}></i>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="d-flex justify-content-center align-items-center"
                                                                style={{
                                                                    width: '32px',
                                                                    height: '32px',
                                                                    backgroundColor: '#fff'
                                                                }}>
                                                                <i className="fas fa-file"
                                                                   style={{color: '#ECE5E0'}}></i>
                                                            </div>
                                                        )
                                                    )
                                                )}
                                            </a>
                                        }
                                    </td>
                                }
                                {Object.keys(rows).map((key, index) => (
                                    <td
                                        className={rows[key].className}
                                        key={index}
                                    >
                                        {rows[key].editField && editAction && can(item, 'edit')
                                            ? <Link className="edit-link" to={editUri ? editUri + '/' + item.id : '#'} onClick={() => {
                                                if (editUri) {
                                                    return
                                                }
                                                editAction(item)
                                            }}>{render(rows[key], key, item)}</Link>

                                            : (rows[key].statusField
                                                ? <span className={`badge-status status-${item[key]}`}>{render(rows[key], key, item)}</span>
                                                : render(rows[key], key, item))
                                        }
                                    </td>
                                ))}
                                <td key={Object.keys(rows).length} className="actions">
                                    {setting.actionsRender ?
                                        setting.actionsRender(item) :
                                        <>
                                            {editAction && can(item, 'edit') &&
                                                <>
                                                    {editUri ?
                                                        <Link className="btn btn-primary" to={editUri + '/' + item.id} onClick={(e) => {
                                                            e.stopPropagation()
                                                        }}>
                                                            <i className="fa-solid fa-pen-to-square me-2"></i>
                                                            {t('app.edit')}
                                                        </Link>
                                                    :
                                                        <Button variant='primary' onClick={(e) => {
                                                            e.stopPropagation()
                                                            editAction(item)
                                                        }}>
                                                            <i className="fa-solid fa-pen-to-square me-2"></i>
                                                            {t('app.edit')}
                                                        </Button>
                                                    }
                                                </>
                                                
                                            }
                                            {customActions && customActions.length && can(item, 'edit') &&
                                                customActions.map((customAction, key) => {
                                                    if (!item) {
                                                        return
                                                    }

                                                    if (customAction.granted && item.hasOwnProperty('isGranted') && typeof item.isGranted[customAction.granted] !== undefined) {
                                                        if (!item.isGranted[customAction.granted]) {
                                                            return
                                                        }
                                                    }

                                                    return (
                                                        <LoadingButton key={key}
                                                                       isLoading={customAction.isLoading ?? false}
                                                                       success={customAction.success}
                                                                       setSuccess={customAction.setSuccess}
                                                                       variant={customAction.variant ?? 'primary'}
                                                                       onClick={(e) => {
                                                                           e.stopPropagation();
                                                                           customAction.method(item)
                                                                       }}>
                                                            {customAction.icon &&
                                                                <i className={c("fa-solid", `fa-${customAction.icon}`, customAction.i18nLabel && 'me-2')}></i>}
                                                            {customAction.i18nLabel && t(customAction.i18nLabel)}
                                                            {!customAction.icon && !customAction.i18nLabel && t('app.action')}
                                                        </LoadingButton>
                                                    )
                                                })
                                            }
                                            {deleteAction && can(item, 'delete') && (!item.hasOwnProperty('deletedAt') || item.deletedAt === null) &&
                                                <Button
                                                    variant='danger'
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        deleteAction(item)
                                                    }}>
                                                    <i className="fa-solid fa-trash me-2"></i>
                                                    {t('app.archive')}
                                                </Button>
                                            }
                                        </>}
                                </td>
                            </tr>
                        )
                    })
                ) : (
                    <tr key={0}>
                        <td
                            colSpan={Object.keys(rows).length + 1 + (selectable ? 1 : 0) + (entity === 'bucket' ? 1 : 0)}
                            className="text-center"
                            key={0}
                        >
                            {!isLoaded
                                ? <Spinner/>
                                : onError
                                    ? <i>{t('app.error')}</i>
                                    : <i>{t('app.no_result')}</i>
                            }
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    )
}

export default _Table
