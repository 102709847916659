import { Col, Row } from "react-bootstrap";
import ChartTitle from "./ChartTitle";

interface ChartSectionProps {
    title?: string;
    className?: string;
    children: React.ReactNode;
}

const ChartSection = ({ title, className, children }: ChartSectionProps) => (
    <>
        {title && (
            <Row className="w-100 my-2">
                <Col xs={12}>
                    <ChartTitle name={title} />
                </Col>
            </Row>
        )}
        <Row className={`d-flex flex-column align-items-center justify-content-center w-100 overflow-hidden my-4 chart-section ${className}`}>
            {children}
        </Row>
    </>
);

export default ChartSection;
