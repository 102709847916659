import { PieChart as RePieChart, Pie, ResponsiveContainer, Cell } from 'recharts';

interface PieChartProps {
    data?: { group_name: string; count: number }[] | undefined;
    colors: string[];
    dataKey?: string;
}

const renderCustomLabel = (data: any[] = [], totalValue: number = 0) => ({
    cx, cy, midAngle, outerRadius, index
}: { cx: number, cy: number, midAngle: number, outerRadius: number, index: number }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 7;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const percentage = ((data[index].count / totalValue) * 100).toFixed(1);

    return (
        <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: '14px' }}>
            {`${data[index].group_name}: ${percentage}%`}
        </text>
    );
};

const PieChart = ({ data = [], colors, dataKey = 'count' }: PieChartProps) => {
    const sortedData = [...data].sort((a, b) => b.count - a.count);

    const top5 = sortedData.slice(0, 5);
    const otherCount = sortedData.slice(5).reduce((sum, item) => sum + item.count, 0);
    const chartData = otherCount > 0 ? [...top5, { group_name: 'Other', count: otherCount }] : top5;

    // Calculate the total value for label percentages
    const totalValue = chartData.reduce((acc, entry) => acc + entry.count, 0);

    return (
        <ResponsiveContainer width="100%" height={350}>
            <RePieChart>
                <Pie
                    data={chartData}
                    dataKey={dataKey}
                    cx="50%"
                    cy="50%"
                    innerRadius={80}
                    outerRadius={150}
                    label={renderCustomLabel(chartData, totalValue)}
                    labelLine={false}
                >
                    {chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                    ))}
                </Pie>
            </RePieChart>
        </ResponsiveContainer>
    );
};

export default PieChart;
