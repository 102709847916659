export const colors = [
    '#646441', '#333745', '#E6FF50', '#BEC3C6', '#EBE5E2', '#000000',
    '#E0E0E0', '#A3A5A9', '#D4D4C8', '#F0F3F2', '#FFFE5C', '#6B6749',
    '#505254', '#6D6F72', '#9CA6A9', '#D6D5C1', '#FBFFA1', '#484942',
    '#454648', '#595A5D', '#8C8D8F', '#C2C3A7', '#FFFDB7', '#9D9D85',
    '#A6A9AA', '#83827B', '#D5D5BB', '#FFFFDC', '#E9E9CE', '#D3D3C3',
    '#B6B8B5', '#CACACA', '#C3C4A3', '#ECF5FF', '#E7F4E6', '#ECE9D8',
    '#6F6F55', '#F6FAD5', '#5F615E', '#DEDBD1', '#DBDBDA', '#F5F8F6',
    '#3D3D40', '#C5C7C6', '#707D7A', '#A1A2A1', '#C1C5C1', '#45454D',
    '#333433', '#565556', '#999791', '#B1B8B7', '#EAE6C5', '#E0DED3',
    '#999D9E', '#777B7E', '#4E4F50', '#1B1B1B', '#7C7876', '#C9D4D3',
    '#484846', '#F5F5F2', '#A6A6A3', '#D1D0CD', '#E4EBEB', '#B3B0B0',
    '#878786', '#D6DFE1', '#F3F7F8', '#2F2F30', '#A5A3A3', '#CACCC9',
    '#E0E6E9', '#BFC1BF', '#797C78', '#373838', '#9A9C9A', '#ECECE6',
    '#787878', '#575857', '#DADBD8', '#999991', '#C7C7C3', '#EEEEEB',
    '#4C4B4B', '#BABBBB', '#CDCECE', '#F4F7F9', '#2B2C2D', '#A0A0A0',
    '#6B6D6C', '#969897', '#3E3F41', '#515151', '#D0D1D1', '#E9EBEB',
    '#F5F7F7',
];
