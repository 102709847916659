import {
    LineChart as ReLineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { LayoutType } from 'recharts/types/util/types';

interface LineChartProps {
    data: any;
    dataKeys: string[];
    xAxisKey: string;
    colors: string[];
    layout?: LayoutType;
    displayLegend?: boolean;
}

const LineChart = ({ data, dataKeys, xAxisKey, colors, layout = 'horizontal', displayLegend = true }: LineChartProps) => {

    const defaultData = [
        { [xAxisKey]: 'Jan', value: 0 },
        { [xAxisKey]: 'Feb', value: 0 },
        { [xAxisKey]: 'Mar', value: 0 }
    ];

    const chartData = (data && data.length > 0) ? data : defaultData;

    return (
        <ResponsiveContainer width="100%" height={350}>
            <ReLineChart data={chartData} layout={layout} margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
            }}>
                <CartesianGrid horizontal={true} vertical={false} />
                {layout === 'horizontal' ? (
                    <>
                        <XAxis dataKey={xAxisKey} />
                        <YAxis axisLine={false} />
                    </>
                ) : (
                    <>
                        <XAxis type="number" />
                        <YAxis dataKey={xAxisKey} type="category" />
                    </>
                )}
                <Tooltip />
                {displayLegend && <Legend verticalAlign="top" align='center' height={36} />}
                {dataKeys.map((key, index) => (
                    <Line
                        key={key}
                        type="linear"
                        dataKey={key}
                        name={key.charAt(0).toUpperCase() + key.slice(1)}
                        stroke={colors[index % colors.length]}
                        strokeWidth={2}
                        activeDot={{ r: 8 }}
                    />
                ))}
            </ReLineChart>
        </ResponsiveContainer>
    );
};

export default LineChart;
