import React, { useEffect, useRef, useState } from "react";

interface ImagePreviewProps {
    src: string;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ src }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [scale, setScale] = useState<number>(1);
    const [position, setPosition] = useState<{ x: number; y: number }>({ x: 50, y: 50 });
    const [dragging, setDragging] = useState<boolean>(false);
    const [startPos, setStartPos] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        const preventScroll = (event: WheelEvent) => event.preventDefault();

        container.addEventListener("wheel", preventScroll, { passive: false });

        return () => {
            container.removeEventListener("wheel", preventScroll);
        };
    }, []);

    const handleWheel = (event: React.WheelEvent<HTMLDivElement>) => {
        if (!containerRef.current) return;

        const container = containerRef.current.getBoundingClientRect();
        const offsetX = ((event.clientX - container.left) / container.width) * 100;
        const offsetY = ((event.clientY - container.top) / container.height) * 100;

        let newScale = scale + (event.deltaY < 0 ? 0.1 : -0.1);
        newScale = Math.max(1, Math.min(newScale, 5));

        setPosition((prev) => ({
            x: prev.x + (offsetX - prev.x) * (1 - scale / newScale),
            y: prev.y + (offsetY - prev.y) * (1 - scale / newScale),
        }));

        setScale(newScale);
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(true);
        setStartPos({ x: event.clientX, y: event.clientY });
    };

    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!dragging || !containerRef.current) return;

        const container = containerRef.current.getBoundingClientRect();
        const dx = ((event.clientX - startPos.x) / container.width) * 100;
        const dy = ((event.clientY - startPos.y) / container.height) * 100;

        if (scale <= 1) {
            setPosition((prev) => ({
                x: prev.x + dx,
                y: prev.y + dy,
            }));
        } else {
            setPosition((prev) => ({
                x: prev.x - dx,
                y: prev.y - dy,
            }));
        }

        setStartPos({ x: event.clientX, y: event.clientY });
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    const resetImage = () => {
        setScale(1);
        setPosition({ x: 50, y: 50 });
    };

    return (
        <div
            ref={containerRef}
            className={`preview w-100 ${dragging ? "cursor-grabbing" : "cursor-grab"}`}
            onWheel={handleWheel}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
        >
            <button
                onClick={resetImage}
                className="reset-btn"
            >
                <i className="fa-solid fa-magnifying-glass-arrows-rotate fs-4"></i>
            </button>
            <div
                className="picture"
                onMouseDown={handleMouseDown}
                style={{
                    backgroundImage: `url(${src})`,
                    backgroundSize: scale > 1 ? `${scale * 90}%` : "contain",
                    backgroundPosition: `${position.x}% ${position.y}%`,
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                    height: "100%",
                }}
            />
        </div>
    );
};

export default ImagePreview;
