import React, {FC, useCallback, useContext, useEffect, useState} from 'react'
import Listing, {CustomAction} from "Components/Listing/Listing";
import Header from "../Components/Layout/Header";
import Bucket from "../Entity/Bucket";
import File from "Entity/File";
import EntityInterface from "../Vendor/Definition/EntityInterface";
import DeleteModal from "../Components/Modal/DeleteModal";
import useTranslationDataUtils from "../Vendor/Utils/TranslationDataUtils";
import Api from "../Vendor/Api";
import {useNavigate, useParams} from "react-router-dom";
import GlobalLoader from "../Vendor/Components/GlobalLoader";
import {UserContext} from "../Vendor/Context/UserContextProvider";
import RefreshModal from "../Components/Modal/RefreshModal";
import FileOffCanvas from "../Components/Offcanvas/file/FileOffCanvas";
import FormEmptyModal from "../Components/Modal/FormEmptyModal";
import {Button, Modal} from "react-bootstrap";
import LoadingButton from "../Components/Button/LoadingButton";
import {useTranslation} from "react-i18next";
import {EditableProperty} from "../Entity/Organization";

export interface BucketProps {
}

const BucketPage: FC<BucketProps> = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const { currentUser } = useContext(UserContext)
    const params = useParams()
    const {translate} = useTranslationDataUtils()
    const [isSearchable, setSearchable] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [bucket, setBucket] = useState<Bucket|undefined>()
    const [file, setFile] = useState<File|undefined>(undefined)
    const [toggleReload, setToggleReload] = useState<string>('')
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const [show, setShow] = useState<boolean>(false)
    const [addShow, setAddShow] = useState<boolean>(false)
    const [matchingProperties, setMatchingProperties] = useState<EditableProperty[]>([])
    const [showModal, setShowModal] = useState(false);
    const [hasPlatform, setHasPlatform] = useState<boolean>(currentUser?.currentOrganization?.platforms
        ? currentUser?.currentOrganization?.platforms?.length > 0
        : false)
    const [isEdited, setIsEdited] = useState<boolean>(false)

    useEffect(() => {
        const collections = currentUser?.currentOrganization?.editable_properties
        const matchingProperties = [];

        const primarySlug = bucket?.primarySlug;
        if (Array.isArray(collections)) {
            for (const collection of collections) {
                if (collection.collection && collection.collection.buckets) {
                    for (const bucketItem of collection.collection.buckets) {
                        if (primarySlug === bucketItem) {
                            matchingProperties.push(collection);
                        }
                    }
                }
            }
        }
        setMatchingProperties(matchingProperties);
    }, [bucket, currentUser])

        const handleSetting = () => {
        setShowModal(true);
    }

    const handleDeleteClose = (reload = false) => {
        setShowDelete(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }
    const handleDeleteShow = () => setShowDelete(true)

    const deleteAction = (file: EntityInterface) => {
        setFile(file as File)
        handleDeleteShow()
    }

    useEffect(() => {
        setSearchable(!!(bucket && (bucket.listingSetting.searchable.length || bucket.listingSetting.searchableByName)));
    }, [bucket])

    useEffect(() => {
        let currentSlug: string = params.slug as string;
        let tempBucket:Bucket|undefined = bucket

        if (currentUser?.currentOrganization?.buckets.length) {
            currentUser.currentOrganization.buckets.forEach(bucket => {
                if (translate(bucket, 'slug') === currentSlug) {
                    tempBucket = bucket
                }
            })
        }

        if (!tempBucket) {
            Api.get(`/b/${currentSlug}/init`)
                .then((response) => {
                    setBucket(response.data as Bucket)
                })
        }
        else {
            setBucket(tempBucket)
        }
    }, [bucket, currentUser, params, translate])

    const handleClose = (reload = false) => {
        setShow(false)
        if (reload) {
            setToggleReload(new Date().toISOString())
        }
    }

    const editNameAction = (file: File) => {
        setFile({...file})
        setAddShow(true)
    }

    const editAction = (file: EntityInterface) => {
        Api.get(`/file/${file.id}`)
            .then((response) => {
                setFile(response.data as File)
                setShow(true)
            })
    }

    const onExited = () => {
        setFile(undefined)
        if(isEdited){
            setToggleReload(new Date().toISOString())
            setIsEdited(false)
        }
    }

    const handlePublish = (file: File) => {
        setShowRefreshModal(true)
        Api.patch(`/file/${file.id}/change-status/published`)
            .then(() => {})
            .catch(() => {})
            .then(() => {
                setToggleReload(new Date().toISOString())
                setShowRefreshModal(false)
            })
    }

    const handleUnPublish = (file: File) => {
        setShowRefreshModal(true)
        Api.patch(`/file/${file.id}/change-status/unpublished`)
            .then(() => {})
            .catch(() => {})
            .then(() => {
                setToggleReload(new Date().toISOString())
                setShowRefreshModal(false)
            })
    }

    const changeStateOnEdit = (status:boolean) => {
        setIsEdited(status)
    }

    const [customActions, setCustomActions] = useState<CustomAction[]>(
    hasPlatform ? [
        {
            method: handlePublish,
            granted: 'publish',
            i18nLabel: 'app.publish',
            variant: 'success',
        },
        {
            method: handleUnPublish,
            granted: 'unPublish',
            i18nLabel: 'app.unPublish',
            variant: 'danger',
        },
    ] : [])

    const [showRefreshModal, setShowRefreshModal] = useState<boolean>(false)

    useEffect(() => {
        const fetchFile = async () => {
            if (params.hasOwnProperty('id')) {
                const id = parseInt(params.id ?? '0', 10);

                if (id > 0) {
                    const response = await Api.get(`/file/${id}`);
                    setFile(response.data as File);
                    setShow(true);
                }
            }
        }
        const timeoutId = setTimeout(fetchFile, 300);

        return () => clearTimeout(timeoutId);
    }, [params.id]);

    if (!bucket) return <GlobalLoader />

    return <>
        <Header
            title={(currentUser?.previousInternal ? '#' + bucket.id + ' - ': '') + translate(bucket, 'name')}
            search={isSearchable ? search : undefined}
            setSearch={isSearchable ? setSearch : undefined}
            setting={handleSetting}
            showSetting={!!matchingProperties.length}
        />
        {file && <>
            <FileOffCanvas
                file={file}
                bucket={bucket}
                show={show}
                handleClose={handleClose}
                onExited={onExited}
                deleteAction={deleteAction}
                editAction={editAction}
                editNameAction={editNameAction}
                changeStateOnEdit={changeStateOnEdit}
            />
            <FormEmptyModal
                i18nTitle={'app.edit_file'}
                show={addShow}
                path={file ? `/file/${file.id}/edit-name` : `/c/${bucket.primarySlug}/new`}
                method={file ? 'patch' : 'post'}
                handleClose={() => {
                    setAddShow(false)
                }}
                onSuccess={(data) => {
                    setFile(data as File)
                    setAddShow(false)
                    changeStateOnEdit(true)
                    if (!show) {
                        setShow(true)
                        setToggleReload(new Date().toISOString())
                    }
                }}
            />
            <DeleteModal
                handleClose={handleDeleteClose}
                show={showDelete}
                path={`/file/${file.id}`}
                name={`#${file.id} - ${file.name}`}
                archive={true}
            />
        </>}
        <Listing
            setting={bucket.listingSetting}
            search={search}
            setSearch={setSearch}
            path={`/b/${bucket.primarySlug}`}
            entity='bucket'
            id={bucket.id}
            slug={translate(bucket, 'slug', true)}
            toggleReload={toggleReload}
            setToggleReload={setToggleReload}
            deleteAction={deleteAction}
            customActions={customActions}
            editAction={editAction}
            selectable={!!bucket.listingSetting.groupedActions.length}
        />
        <RefreshModal show={showRefreshModal} />
        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Paramètres</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul className={'choose-brand'}>
                    {matchingProperties.map((property, index) => (
                        <li key={index}>
                            <LoadingButton variant="light"
                                           isLoading={false}
                                           onClick={() => {
                                           }}>{property.name}
                            </LoadingButton>
                        </li>
                    ))}
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}

export default BucketPage
