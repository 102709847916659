import { useState } from "react";

interface UseFileNameValidationProps {
    errorMessage: string;
}

const useFileNameValidation = ({ errorMessage }: UseFileNameValidationProps) => {
    const [fileName, setFileName] = useState<string>("");
    const [error, setError] = useState<string | null>(null);

    const validateFileName = (name: string): boolean => {
        const invalidFileName = /[\/\\]/;

        if (invalidFileName.test(name)) {
            setError(errorMessage);
            return false;
        }

        setError(null);
        return true;
    };

    const handleFileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFileName(value);
        validateFileName(value);
    };

    return { fileName, error, setFileName, handleFileNameChange };
};

export default useFileNameValidation;
