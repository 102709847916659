import { FC, useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Navbar as GlobalNavbar, NavbarItem } from "Components/Layout/Navbar";
import Header from "Components/Layout/Header";
import { useTranslation } from "react-i18next";
import { UserContext } from "Vendor/Context/UserContextProvider";

const Navbar: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [items, setItems] = useState<NavbarItem[]>([])
    const { currentUser, getInitialPage } = useContext(UserContext)

    if (!currentUser?.currentOrganization?.options.includes('data_visualization')) {
        navigate(getInitialPage())
    }

    useEffect(() => {
        let items: NavbarItem[] = [
            {
                i18nLabel: 'app.app',
                path: ''
            },
        ]

        if ((currentUser?.currentOrganization?.platforms?.length ?? 0) >= 1) {
            items.push({
                i18nLabel: 'app.platform',
                path: 'platform'
            })
        }
        setItems(items)
    }, [currentUser?.currentOrganization?.platforms])

    return (
        <>
            <Header title={t("app.dashboard")} />
            <GlobalNavbar locationBase='/dashboard' items={items} />
            <Outlet />
        </>
    )
}

export default Navbar
