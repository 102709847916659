import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import BlankPage from "Components/Layout/BlankPage";

import { BarChart, LineChart, PieChart } from "Components/Dashboard/Charts";
import Api from "Vendor/Api";
import ChartSection from "Components/Dashboard/ChartSection";
import ChartContainer from "Components/Dashboard/ChartContainer";

import GlobalLoader from "Vendor/Components/GlobalLoader";
import { UserContext } from "Vendor/Context/UserContextProvider";
import { colors } from "Vendor/Utils/Colors";
import { GroupData, Dashboard, ContentItem, TransformedGroupData, GroupTotal } from './DataType';

const PlatformPage: FC = () => {
    const { t } = useTranslation()
    const { currentUser } = useContext(UserContext)
    const [data, setData] = useState<Dashboard>()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Api.get(`/dashboard/platform`);
                setData(response.data);
            } catch (error) {
                console.error("Failed to fetch dashboard data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const transformGroupData = (
        data: GroupData[],
        xAxisKey: string,
        limit: number = 5
    ): TransformedGroupData[] => {
        return data.map((item) => {
            const groups = Object.entries(item)
                .filter(([key]) => key !== xAxisKey && key !== 'interval')
                .sort(([, valueA], [, valueB]) => Number(valueB) - Number(valueA));

            const topGroups = groups.slice(0, limit);
            const otherGroups = groups.slice(limit);

            const otherSum = otherGroups.reduce((sum, [, value]) => sum + Number(value), 0);

            const transformedItem: TransformedGroupData = {
                [xAxisKey]: item[xAxisKey] as string,
                Others: otherSum,
            };

            topGroups.forEach(([key, value]) => {
                transformedItem[key] = Number(value);
            });

            return transformedItem;
        });
    };

    const calculateGroupTotals = (data: GroupData[]): GroupTotal[] => {
        return data.map((item) => {
            let total = 0;

            for (const key in item) {
                if (key !== 'name' && key !== 'interval' && key !== 'year' && key !== 'month') {
                    const value = item[key];
                    if (typeof value === 'string') {
                        total += parseInt(value, 10) || 0;
                    }
                }
            }

            const result: GroupTotal = { total };

            if ('name' in item) {
                result.name = item.name;
            }

            if ('month' in item) {
                result.month = item.month as string;
            }

            return result;
        });
    };

    const transformDataByContentType = (inputData: Record<string, ContentItem[]> = {}): TransformedGroupData[] =>
        Object.entries(inputData).flatMap(([key, items]) =>
            items.map(({ name, value }) => ({
                name,
                [key]: value,
            }))
        );

    if (isLoading) {
        return <GlobalLoader />;
    }

    if (!data || !currentUser?.currentOrganization?.options.includes("data_visualization")) {
        return null;
    }

    const consultationPerGroupData = calculateGroupTotals(data?.consultationsPerGroup || []);
    const downloadsPerGroupData = calculateGroupTotals(data?.downloadsPerGroup || []);
    const shoppingPerGroupData = transformGroupData(data?.shoppingsPerGroup || [], 'month', 3);
    const preOrderPerGroupData = transformGroupData(data?.preOrdersPerGroup || [], 'month', 3);
    const consultationByContentType = transformDataByContentType(data?.totalConsultationsByContentType);
    const downloadByContentType = transformDataByContentType(data?.totalDownloadsByContentType);

    return (
        <BlankPage>
            <Container>
                <Row>
                    <Col xs={12} lg={6}>
                        <ChartSection title={t('app.dashboard_charts_title.number_of_platform_users')} className="h-75">
                            <Col className="d-flex align-items-center justify-content-center h-100">
                                <div className="d-flex align-items-center justify-content-center text-center rounded-4" style={{ width: '270px', height: '150px', backgroundColor: '#fff', border: '1px solid #000' }}>
                                    <h1 style={{ fontSize: '3rem' }} className="font-weight-bold">{data?.users || 0}</h1>
                                </div>
                            </Col>
                        </ChartSection>
                    </Col>
                    <Col xs={12} lg={6}>
                        <ChartSection title={t('app.dashboard_charts_title.type_of_platform_users')}>
                            <ChartContainer>
                                <PieChart data={data?.usersType} colors={colors} />
                            </ChartContainer>
                        </ChartSection>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} lg={6}>
                        <ChartSection title={t('app.dashboard_charts_title.visits')}>
                            <ChartContainer title={t('app.dashboard_charts_title.number_of_visits')}>
                                <LineChart
                                    data={data?.visits || []}
                                    dataKeys={['value']}
                                    xAxisKey="month"
                                    colors={colors}
                                    layout="horizontal"
                                />
                            </ChartContainer>
                        </ChartSection>
                    </Col>
                    <Col xs={12} lg={6}>
                        <ChartSection title={t('app.dashboard_charts_title.visitors')}>
                            <ChartContainer title={t('app.dashboard_charts_title.type_of_visits')}>
                                <BarChart
                                    layout="vertical"
                                    data={data?.visitsPerGroup || []}
                                    xAxisKey="name"
                                    yAxisType="number"
                                    dataKeys={['value']}
                                    colors={colors}
                                />
                            </ChartContainer>
                        </ChartSection>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} lg={6}>
                        <ChartSection title={t('app.dashboard_charts_title.consultations')}>
                            <ChartContainer title={t('app.dashboard_charts_title.number_of_consultations_by_type_of_content')}>
                                <BarChart
                                    layout="vertical"
                                    data={consultationByContentType}
                                    xAxisKey={"name"}
                                    yAxisType="number"
                                    dataKeys={Object.keys(data?.totalConsultationsByContentType || [])}
                                    colors={colors}
                                    stacked
                                />
                            </ChartContainer>
                            <ChartContainer title={t('app.dashboard_charts_title.number_of_consultations')}>
                                <BarChart
                                    layout="vertical"
                                    data={consultationPerGroupData}
                                    xAxisKey={'name'}
                                    yAxisType="number"
                                    dataKeys={
                                        consultationPerGroupData && consultationPerGroupData.length > 0
                                            ? Object.keys(consultationPerGroupData[0]).filter(key => key !== 'name')
                                            : []
                                    }
                                    colors={colors}
                                    stacked
                                />
                            </ChartContainer>
                        </ChartSection>
                    </Col>
                    <Col xs={12} lg={6}>
                        <ChartSection title={t('app.dashboard_charts_title.downloads')}>
                            <ChartContainer title={t('app.dashboard_charts_title.number_of_downloads_by_type_of_content')}>
                                <BarChart
                                    layout="vertical"
                                    data={downloadByContentType}
                                    xAxisKey={'name'}
                                    yAxisType="number"
                                    dataKeys={Object.keys(data?.totalDownloadsByContentType || [])}
                                    colors={colors}
                                    stacked
                                />
                            </ChartContainer>
                            <ChartContainer title={t('app.dashboard_charts_title.number_of_downloads')}>
                                <BarChart
                                    layout="vertical"
                                    data={downloadsPerGroupData}
                                    xAxisKey={'name'}
                                    yAxisType="number"
                                    dataKeys={
                                        downloadsPerGroupData && downloadsPerGroupData.length > 0
                                            ? Object.keys(downloadsPerGroupData[0]).filter(key => key !== 'name')
                                            : []
                                    }
                                    colors={colors}
                                    stacked
                                />
                            </ChartContainer>
                        </ChartSection>
                    </Col>
                </Row>
                <Row>
                    {currentUser?.currentOrganization?.options.includes('shopping') &&
                        <>
                            {currentUser?.currentOrganization?.setting.shoppingTypes.includes('shopping') && <Col xs={12} lg={6}>
                                <ChartSection title={t('app.dashboard_charts_title.shopping')}>
                                    <ChartContainer title={t('app.dashboard_charts_title.number_of_shoppings')}>
                                        <LineChart
                                            data={data?.shoppings}
                                            dataKeys={['value']}
                                            xAxisKey="month"
                                            colors={colors}
                                            layout="horizontal"
                                            displayLegend={false}
                                        />
                                    </ChartContainer>
                                    <ChartContainer title={t('app.dashboard_charts_title.number_of_shopping_requests_by_user_groups')}>
                                        <BarChart
                                            layout="horizontal"
                                            data={shoppingPerGroupData || []}
                                            xAxisKey="month"
                                            yAxisType="number"
                                            dataKeys={
                                                shoppingPerGroupData && shoppingPerGroupData.length > 0
                                                    ? Object.keys(shoppingPerGroupData[0]).filter(key => key !== 'name' && key !== 'month' && key !== 'year')
                                                    : []
                                            }
                                            colors={colors}
                                        />
                                    </ChartContainer>
                                </ChartSection>
                            </Col>}
                            {currentUser?.currentOrganization?.setting.shoppingTypes.includes('pre-order') && <Col xs={12} lg={6}>
                                <ChartSection title={t('app.dashboard_charts_title.pre_orders')}>
                                    <ChartContainer title={t('app.dashboard_charts_title.number_of_pre_orders')}>
                                        <LineChart
                                            data={data?.preOrders}
                                            dataKeys={['value']}
                                            xAxisKey="month"
                                            colors={colors}
                                            layout="horizontal"
                                            displayLegend={false}
                                        />
                                    </ChartContainer>
                                    <ChartContainer title={t('app.dashboard_charts_title.number_of_pre_order_requests_by_user_groups')}>
                                        <BarChart
                                            layout="horizontal"
                                            data={preOrderPerGroupData}
                                            xAxisKey="month"
                                            yAxisType="number"
                                            dataKeys={
                                                preOrderPerGroupData && preOrderPerGroupData.length > 0
                                                    ? Object.keys(preOrderPerGroupData[0]).filter(key => key !== 'name' && key !== 'month' && key !== 'year')
                                                    : []
                                            }
                                            colors={colors}
                                        />
                                    </ChartContainer>
                                </ChartSection>
                            </Col>}
                        </>}
                </Row>
            </Container>
        </BlankPage>
    )
}

export default PlatformPage
